html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}

.slide-ml-transition {
  transition: margin-left 0.3s ease-in-out, opacity 0.15s ease-in-out;
}

.MuiButton-root {
  text-transform: none;
}
.MuiInput-underline:before {
  border-bottom-color: "rgba(0,0,0,0.05)";
}
.MuiListSubheader-root {
  background-color: transparent;
}
